/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable semi */
import axios from 'axios';

/* eslint-disable */
const transformRequest = (jsonData = {}) => Object.entries(jsonData)
  .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
  .join('&');

export default {
  namespaced: true,
  state: {
    topLevelCategory: process.env.VUE_APP_TOP_LEVEL_CATEGORY,
    dataAPIBase: process.env.VUE_APP_OCAPI_DATA_URL,
    shopAPIBase: process.env.VUE_APP_OCAPI_SHOP_URL,
    dataAuthUrl: process.env.VUE_APP_OCAPI_DATA_AUTH_URL,
    shopClientId: process.env.VUE_APP_OCAPI_SHOP_CLIENT_ID,
    dataClientId: process.env.VUE_APP_OCAPI_DATA_CLIENT_ID,
    dataClientSecret: process.env.VUE_APP_OCAPI_DATA_CLIENT_SECRET,
    siteId: process.env.VUE_APP_OCAPI_SITE_ID,
    libraryId: process.env.VUE_APP_LIBRARY_ID,
    customerListId: process.env.VUE_APP_OCAPI_CUSTOMER_LIST_ID || process.env.VUE_APP_OCAPI_SITE_ID,
    enableProdReportData: process.env.VUE_APP_ENABLE_PROD_REPORT_DATA || false,
    shopAPIBaseProd: process.env.VUE_APP_OCAPI_SHOP_URL_PROD || process.env.VUE_APP_OCAPI_SHOP_URL,
    customer: null,
    customerAuth: null,
    customerExtProfile: null,
    customerGroup: null,
    allCustomerGroups: null,
    dataAuthToken: null,
    dataAuthTokenExpiration: null,
    login: null,
    lastLogin: null
  },
  mutations: {
    setDataAuthToken(state, payload) {
      state.dataAuthToken = payload;
    },
    setDataAuthTokenExpiration(state, payload) {
      state.dataAuthTokenExpiration = payload;
    },
    setCustomerAuth(state, payload) {
      state.customerAuth = payload;
    },
    setCustomer(state, payload) {
      state.customer = payload;
    },
    setCustomerExtProfile(state, payload) {
      state.customerExtProfile = payload;
    },
    setCustomerGroup(state, payload) {
      state.customerGroup = payload;
    },
    setAllCustomerGroups(state, payload) {
      state.allCustomerGroups = payload;
    },
    setCurrentUserLogin(state, payload) {
      state.login = payload;
    },
    setLastLogin(state, payload) {
      state.lastLogin = payload;
    }
  },
  actions: {
    init({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuth')
          .then(resolve)
          .catch(reject);
      });
    },
    // auth
    fetchAuthToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        const today = new Date();
        if (state.dataAuthToken !== null && today.getTime() < state.dataAuthTokenExpiration.getTime()) {
          // return current token if not expired
          resolve(state.dataAuthToken);
        } else {
          const auth = btoa(`${state.dataClientId}:${state.dataClientSecret}`);
          // request a new token
          axios({
            method: 'post',
            url: '/dwsso/oauth2/access_token',
            baseURL: state.dataAuthUrl,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Basic ${auth}`,
            },
            transformRequest: jsonData => transformRequest(jsonData),
            data: {
              grant_type: 'client_credentials',
            },
          })
            .then((response) => {
              const expires = today.getTime() + response.data.expires_in * 1000;
              commit('setDataAuthToken', response.data.access_token);
              commit('setDataAuthTokenExpiration', new Date(expires));
              resolve(state.dataAuthToken);
            })
            .catch(reject);
        }
      });
    },
    fetchUserAuthWithToken({ commit, dispatch, getters, state }) {
      return new Promise((resolve, reject) => {
        Promise.resolve()
          .then(() => {
            if (getters.parsedCustomerAuthToken) {
              const now = new Date();
              const tokenExpiration = new Date(getters.parsedCustomerAuthToken.exp * 1000);
              if (now.getTime() < tokenExpiration.getTime()) {
                resolve();
              }
            }
            return dispatch('fetchAuthToken');
          })
          .then(() => axios({
            method: 'post',
            url: '/customers/auth/trustedsystem',
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`
            },
            data: {
              client_id: state.shopClientId,
              login: state.login
            }
          }))
          .then(response => {
            commit('setCustomer', response.data);
            commit('setCustomerAuth', response.headers.authorization);
            resolve(response);
          })
          .catch(reject)
      });
    },
    fetchUserAuth({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        const username = 'will.anderson@hallmarkbusinessconnections.com';
        const password = '2uI#Ml1~vg';
        const auth = btoa(`${username}:${password}`);
        // request a new token
        axios({
          method: 'post',
          url: '/customers/auth',
          baseURL: getters.shopApiUrl,
          headers: {
            Authorization: `Basic ${auth}`,
          },
          params: {
            client_id: state.shopClientId,
          },
          data: {
            type: 'credentials',
          },
        })
          .then((response) => {
            commit('setCustomer', response.data);
            commit('setCustomerAuth', response.headers.authorization);
            resolve(state.dataAuthToken);
          })
          .catch(reject);
      });
    },
    fetchGuestAuth({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/customers/auth',
          baseURL: getters.shopApiUrl,
          params: {
            client_id: state.shopClientId,
          },
          data: {
            type: 'guest',
          }
        })
          .then((response) => {
            commit('setCustomer', response.data);
            commit('setCustomerAuth', response.headers.authorization);
            resolve(response);
          })
          .catch(reject);
      });
    },
    searchCustomers({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => axios({
            method: 'post',
            url: `/customer_lists/${state.customerListId}/customer_search`,
            baseURL: getters.dataApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`
            },
            data: {
              ...payload
            }
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    
    // customer
    createCustomerExtProfile({ commit, state, getters, rootGetters }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/customers/ext_profile',
          baseURL: getters.shopApiUrl,
          headers: {
            Authorization: state.customerAuth
          },
          data: {
            authentication_provider_id: 'PingOne',
            external_id: rootGetters['ping/parsedIdToken'].preferred_username,
            email: rootGetters['ping/parsedIdToken'].email
          }
        })
          .then((response) => {
            commit('setCustomerExtProfile', response.data)
            resolve(response);
          })
          .catch(reject);
      });
    },
    fetchCustomer({ commit, state, getters }, payload) {
      const customer_id = payload || state.customer.customer_id;
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/customers/${customer_id}`,
          baseURL: getters.shopApiUrl,
          headers: {
            Authorization: state.customerAuth,
          },
        })
          .then(response => {
            commit('setCustomer', response.data)
            resolve(response);
          })
          .catch(reject);
      });
    },
    updateCustomer({ state, commit, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'patch',
            url: `/customers/${state.customer.customer_id}`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: {
              ...payload
            }
          }))
          .then(response => {
            commit('setCustomer', response.data)
            resolve(response);
          })
          .catch(reject);
      });
    },
    fetchCustomerBaskets({ state, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'get',
            url: `/customers/${state.customer.customer_id}/baskets`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
          }))
          .then((response) => resolve(response.data.baskets || []))
          .catch(reject);
      });
    },

    fetchAllCustomerGroups({ state, getters, commit, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => axios({
            method: 'get',
            url: `/sites/${state.siteId}/customer_groups`,
            baseURL: getters.dataApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`
            },
            params: {
              select: '(data.(id,description,type,c_clientConfiguration))',
              count: 200
            }
          }))
          .then(response => {
            const groupDetails = []
            const groups = response.data.data || []

            groupDetails.push({
              id: 'All',
              name: 'All Clients',
            })

            groups.forEach(group => {
              if(group.type == 'dynamic')
              {
                try {
                  const clientConfig = group.c_clientConfiguration ? JSON.parse(group.c_clientConfiguration) : null
                  groupDetails.push({
                    id: group.id,
                    name: group.description,
                    clientMinimum: clientConfig?.customFields?.clientMinimum,
                  })
                } catch (e) {
                  console.error(group.description + ' : ' + e)
                }
              }
            });
            commit('setAllCustomerGroups', groupDetails);

            // if(!rootGetters['ping/isAdmin'])
            // {
              commit('admin/setGlobalClientSelected', 
                (state.allCustomerGroups.filter(group => group.id === rootGetters['ping/parsedIdToken'].populationID))[0].id, 
                {root: true});
            // }

            resolve(response);
          })
          .catch(reject);
      });
    },

    fetchCustomerGroup({ state, getters, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => axios({
            method: 'get',
            url: `/sites/${state.siteId}/customer_groups/${payload}`,
            baseURL: getters.dataApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`
            },
            params: {
              select: '(**,member_count)'
            }
          }))
          .then(resolve)
          .catch(reject);
      });
    },

    fetchCurrentUserCustomerGroup({ state, getters, commit, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchCustomerGroup', rootGetters['ping/parsedIdToken'].populationID)
          .then(response => {
            commit('setCustomerGroup', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },

    updateCustomerGroup({ state, getters, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => axios({
            method: 'patch',
            url: `/sites/${state.siteId}/customer_groups/${payload.customerGroupID}`,
            baseURL: getters.dataApiUrl,
            headers: {
              Authorization: `Bearer ${state.dataAuthToken}`
            },
            data: {
              ...payload.data
            }
          }))
          .then(resolve)
          .catch(reject);
      });
    },

    // basket
    createBasket({ state, getters }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'post',
            url: '/baskets',
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            params: {
              client_id: state.shopClientId,
            },
          }))
          .then(resolve)
          .catch(reject);
      });
    },
    deleteBasket({ state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'delete',
            url: `/baskets/${payload}`,
            baseURL: state.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
          }))
          .then(resolve)
          .catch(reject);
      });
    },
    setBasketPaymentInstrument({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'post',
            url: `/baskets/${payload.basket_id}/payment_instruments`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: payload.data,
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    setBasketBillingAddress({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'put',
            url: `/baskets/${payload.basket_id}/billing_address`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: payload.data,
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    setShipmentShippingAddress({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'put',
            url: `/baskets/${payload.basket_id}/shipments/${payload.shipment_id}/shipping_address`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: payload.shipping_address,
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    setShipmentShippingMethod({ state, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'put',
            url: `/baskets/${payload.basket_id}/shipments/${payload.shipment_id}/shipping_method`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: payload.shipping_method,
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    addItemToBasket({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'post',
            url: `/baskets/${payload.basket_id}/items`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            params: {
              client_id: state.shopClientId,
            },
            data: [
              payload.item
            ],
          }))
          .then(resolve)
          .catch(reject);
      });
    },
    createOrder({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchUserAuthWithToken')
          .then(() => axios({
            method: 'post',
            url: `/orders`,
            baseURL: getters.shopApiUrl,
            headers: {
              Authorization: state.customerAuth,
            },
            data: {
              basket_id: payload.basket_id
            },
          }))
          .then(resolve)
          .catch(reject);
      })
    },
    // categories
    fetchCategories({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/categories/${payload.category_id}`,
          baseURL: getters.shopApiUrl,
          params: {
            client_id: state.shopClientId,
            ...payload.params
          },
        })
        .then(resolve)
        .catch(reject);
      });
    },

    // products
    fetchProducts({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/product_search',
          baseURL: getters.shopApiUrl,
          params: {
            refine: `cgid=${payload.cgid}`,
            count: 200,
            start: payload?.start || 0,
            expand: 'images',
            client_id: state.shopClientId,
          },
        })
          .then(resolve)
          .catch(reject);
      });
    },

    fetchAllProducts({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchProducts', { cgid: state.topLevelCategory })
          .then(response => {
            const remainingCalls = [];
            let hits = response.data.hits;
            if (response.data.total > response.data.count) {
              for (let i = response.data.count; i < response.data.total; i += response.data.count) {
                remainingCalls.push(dispatch('fetchProducts', { cgid: state.topLevelCategory, start: i }));
              }
              Promise.all(remainingCalls)
                .then(responses => {
                  responses.forEach(response => {
                    hits = hits.concat(response.data.hits);
                  });
                  resolve(hits);
                })
                .catch(reject);
            } else {
              resolve(hits);
            }
          });
      })
    },

    fetchMultipleProducts({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/products/(${payload.product_ids.join(',')})`,
          baseURL: getters.shopApiUrl,
          params: {
            client_id: state.shopClientId,
          }
        })
          .then(resolve)
          .catch(reject);
      });
    },

    fetchProduct({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/products/${payload.product_id}`,
          baseURL: getters.shopApiUrl,
          params: {
            expand: 'images',
            client_id: state.shopClientId,
          },
        })
        .then(resolve)
        .catch(reject);
      });
    },
    fetchLibraryFolder({ state, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => {
            return axios({
              method: 'get',
              url: `/libraries/${state.libraryId}/folders/${payload.folder_id}/content`,
              baseURL: getters.dataApiUrl,
              params: {
                client_id: state.dataClientId,
              },
              headers: {
                Authorization: `Bearer ${state.dataAuthToken}`,
              },
            });
          })
          .then(resolve)
          .catch(reject);
      });
    },
    // orders
    fetchOrders({ state, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchAuthToken')
          .then(() => {
            return axios({
              method: 'post',
              url: '/order_search',
              baseURL: state.enableProdReportData ? state.shopAPIBaseProd : getters.shopApiUrl,
              params: {
                client_id: state.shopClientId,
              },
              headers: {
                Authorization: `Bearer ${state.dataAuthToken}`,
              },
              data: payload
            })
          })
        .then(resolve)
        .catch(reject);
      });
    },
  },
  getters: {
    shopApiUrl(state) {
      return `${state.shopAPIBase}`;
    },
    dataApiUrl(state) {
      return `${state.dataAPIBase}`;
    },
    parsedCustomerAuthToken(state) {
      if (state.customerAuth) {
        const token = state.customerAuth.split('Bearer ')[1];
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    },
    getClientConfig: () => (customerGroup) => {
      if (!customerGroup) {
        return null;
      }
      if ('c_clientConfiguration' in customerGroup) {
        return JSON.parse(customerGroup.c_clientConfiguration);
      }
      return null;
    },
    getSenderProfiles: () => (customerGroup) => {
      if (!customerGroup) {
        return [];
      }
      if ('c_senderProfiles' in customerGroup) {
        return JSON.parse(customerGroup.c_senderProfiles);
      }
      return [];
    },
    getClientModelLine: () => (customerGroup) => {
      if (!customerGroup) {
        return [];
      }
      if ('c_clientModelLine' in customerGroup) {
        return JSON.parse(customerGroup.c_clientModelLine);
      }
      return [];
    },
    clientConfig(state, getters) {
      return getters.getClientConfig(state.customerGroup);
    },
    senderProfiles(state, getters) {
      return getters.getSenderProfiles(state.customerGroup);
    },
    allCustomerGroups(state) {
      return state.allCustomerGroups
    }
  }
};
