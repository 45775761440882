<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      Hallmark Business Connections
      <span></span>
      <br />
      <p class="mb-0">
        Copyright © {{ this.currentYear }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      currentYear: null
    }
  },
  computed: {
    ...mapState('admin', [
      'globalYear'
    ])
  },
  mounted() {
    this.currentYear = this.globalYear.year
  }

}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
